/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  /* border-bottom: 1px solid rgb(189, 189, 189); */
  background-color: black;
  color: white;
  /* background-color: #333; */
  /* color: white; */
}

.navbar-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.navbar-menu li {
  /* border: 1px solid black; */
  margin: 4px 20px;
}

.navbar-menu a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.navbar-menu a:hover {
  color: red;
}

.navbar-toggle {
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: rgb(15, 97, 134);
}

.logo {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  color: rgb(15, 97, 134);
  margin-left: 1rem;
}

.onMobile {
  display: none;
}

.profile-dropdown {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

/* Navbar.css */
/* ... (previous CSS code) */

@media screen and (max-width: 768px) {
  .navbar-register-btn {
    display: none;
  }
  .profile-dropdown {
    display: none;
  }
  .onMobile {
    display: flex;
    column-gap: 1rem;
  }
  .navbar-menu {
    flex-direction: row;
    display: "flex";
    flex-direction: column;
    background-color: black;
    position: absolute;
    top: 69px;
    left: 0;
    width: 100%;
    padding: 10px 0px;
    z-index: 1;
    animation: moveRight 0.2s linear;
  }

  .close {
    display: none;
  }

  .navbar-menu li {
    margin: 15px 15px;
  }

  .navbar-toggle {
    display: flex;
  }

  @keyframes moveRight {
    0% {
      left: -100%;
    }
    100% {
      left: calc(100% - 100%); /* Assuming the container width is 100% */
    }
  }
}
