.footer-main {
  background-color: black;
  padding: 2rem 0.7rem;
}

.footer-main .links {
  display: flex;
  column-gap: 2rem;
  color: red;
  margin: 1rem 0rem;
}

.footer-main .social-media {
  display: flex;
  column-gap: 1.4rem;
}

@media (max-width: 500px) {
  .footer-main .social-media {
    width: fit-content;
    margin: auto;
  }
}

.footer-main a {
  text-decoration: none;
  color: white;
}
