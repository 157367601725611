.contactus-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
}

.contactus-main .title {
  font-size: 28px;
  text-align: center;
  margin: 10px 0px;
  font-weight: 500;
  color: red;
}

.contactus-main .form-title {
  color: white;
}

.contactus-main > div {
  width: -webkit-fill-available !important;
}

.contactus-form .form {
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  border: 1px solid rgb(138, 138, 138);
}

.contactus-form .textArea {
  border-radius: 0.5rem;
  padding: 6px;
  resize: none;
}

.contactus-form input {
  width: 100%;
  border-radius: 6px;
  padding: 0.5rem;
  border: 1px solid rgb(150, 150, 150) !important;
}

.ant-input::placeholder {
  color: rgb(88, 88, 88);
}
